import {
  useCustomerCartIdQuery,
  fetchGuestToken,
  GraphQlErrorsResponse,
  ConfigurableProductVariantFragment,
} from '@/api'
import { queryClient } from '@/common/services'
import { removeCookie } from '@/common/utils/cookie-utils'
import { restoreCustomerToken } from '@/providers/auth/utils'
import { FlashMessage } from '@/providers/flash-messages'
import { AddToCartData } from '../hooks'
import { AddToCartTrackingConfig } from '@/providers'
import { ProductConfigurableVariants } from '@/components/variants'
import { RecommendedProductTrackingOptions } from '@/common/services/tracking/google/gtm-tracker'
import { ProductType } from '@/common/types/product-types'
import { consoleError } from '@/common/utils/console'

export type AddToCartParams = Omit<AddToCartData, 'cartId' | 'refreshTokens'> &
  Partial<RecommendedProductTrackingOptions>

export type SimpleVariantAddToCartParams = {
  productType: ProductType
  productName?: string
  parentSku?: string
  sku?: string
  brand?: string
  price?: number
  category?: string
  productPosition?: number
}

export type AddToCartEventHandlerParams = {
  count: number
  product: ProductConfigurableVariants
  variant?: ConfigurableProductVariantFragment
  addToCart: (
    data: AddToCartParams,
    trackingConfig?: AddToCartTrackingConfig,
  ) => Promise<void>
}

export const fetchCustomerCartId = async () => {
  const response = await queryClient.fetchQuery({
    queryKey: useCustomerCartIdQuery.getKey(),
    queryFn: useCustomerCartIdQuery.fetcher(),
    retry: 3,
    retryDelay: 1000,
  })

  if (!response.customerCart || response.errors) {
    removeCookie('customerToken')
    removeCookie('customerSegment')
    return undefined
  }

  return response.customerCart.id
}

export const handleCartGraphqlError = async <T extends Record<string, unknown>>(
  data: T,
  handlerFn: (
    data: T & { cartId: string },
  ) => Promise<FlashMessage[] | undefined>,
) => {
  const response = await fetchGuestToken()

  if (response.error || !response.data) {
    consoleError('error', response.error)
    throw new Error(JSON.stringify(response.error))
  } else {
    if (!restoreCustomerToken()) {
      return handlerFn({ ...data, cartId: response.data })
    } else {
      const customerCartId = await fetchCustomerCartId()
      const newCartId = customerCartId || response.data

      return handlerFn({ ...data, cartId: newCartId })
    }
  }
}

export const containsGraphqlErrorResponseSpecificCategory = (
  categories: string[],
  errs?: GraphQlErrorsResponse['errors'],
) => {
  return (
    errs &&
    errs?.some(
      (err) =>
        err.extensions?.category &&
        categories.includes(err.extensions.category),
    )
  )
}
