/* eslint-disable no-console */
/**
 * @param message The error message or error object
 * @param ...args Additional arguments to be logged
 */
export function consoleError(message: string | Error, ...args: any[]): void {
  const timestamp = new Date().toISOString()
  const prefix = `[ERROR] ${timestamp}:`

  if (message instanceof Error) {
    console.error(prefix, message.message, ...args)
    if (message.stack) {
      console.error(message.stack)
    }
  } else {
    console.error(prefix, message, ...args)
  }
}
